import Vue from 'vue'
import Router from 'vue-router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import VueMathjax from 'vue-mathjax'
import App from './App.vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

Vue.config.productionTip = false

Vue.use(Router)
Vue.use(VueAxios, axios)
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(VueApexCharts)
Vue.use(VueMathjax)

// eslint-disable-next-line
Vue.component('apexchart', VueApexCharts)
const Welcome = () => import('./components/Welcome.vue')
const BlockstreamMiningNote = () => import('./components/BlockstreamMiningNote.vue')
const Alternative261218 = () => import('./components/Alternative261218.vue')
const BfxsaIndex = () => import('./components/BfxsaIndex.vue')

const router = new Router({
  routes: [
    { path: '/blockstream-mining-note', component: BlockstreamMiningNote },
    { path: '/alternative-2612', component: Alternative261218 },
    { path: '/bfxsa-index', component: BfxsaIndex },
    { path: '/', component: Welcome }
  ]
})

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
